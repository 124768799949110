
.lawyer-head{
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: var(--contents-padding-top);
    background-image: url(/images/sub/lawyer/lawyer-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &__info{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background-color: rgba(23, 28, 33, .8);
        }
        >*{
            position: relative;
            z-index: 1;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .lawyer-head{
        &__info{
            &::before{
                left: 0;
                transform: translateX(0);
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

