
::v-deep {
    .v-tabs {
        background-color: transparent !important;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .v-tabs {
            .v-tab{
                min-width: 240px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
