
.media-item{
    display: block;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .media-item{
        display: block;
        &__thumb{
            position: relative;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(23, 28, 33, .4);
                opacity: 0;
                visibility: hidden;
                transition: .3s ease-out;
            }
        }
        &:hover{
            .media-item__thumb{
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

