
.image-row{
    --image-padding: 20px;
    &__image{
        &__inner{
            max-width: 432px;
            position: relative;
            padding: var(--image-padding) var(--image-padding) 0 0;
        }
        &__bg{
            position: absolute;
            width: calc(100% - var(--image-padding));
            height: calc(100% - var(--image-padding));
            right: 0;
            top: 0;
            background-color: var(--v-primary-lighten4);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .image-row{
        --image-padding: 48px;
        &__image{
            padding-right: 54px;
        }
        &--reverse{
            flex-direction: row-reverse;
            .image-row__image{
                padding-right: 0;
                padding-left: 54px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
