
.v-list{
    padding: 0;
    width: 160px;
}
.v-list-item{
    padding: 0;
    min-height: 60px;
    &::before,
    &::after{
        display: none;
    }
    &__content{
        flex-wrap: nowrap;
        &::after{
            content: "";   
            height: 12px;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            margin-left: 8px;
            background-image: url(/images/icon/list-active.svg);
            background-position: right 1px center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-out;
        }
    }
    &__title{
        font-size: 1.8rem;
        color: var(--v-grey-lighten1);
        transition: .3s ease-out;        
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    &--active{
        .v-list-item__content{
            &::after{
                opacity: 1;
                visibility: visible;
            }
        }
        .v-list-item__title{
            color: var(--v-primary-base);
            font-weight: 700;
        }
    }
}
.business-item{
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    padding: 0;
    transition: .3s ease-out;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/images/sub/business/business-active.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        visibility: hidden;
        transition: .3s ease-out;
    }
    &__inner{
        position: relative;
        z-index: 1;
        >.row--lg,
        >.row--lg>[class*=col]{
            transition: .3s ease-out;
        }
        ::v-deep{
            .icon-plus{
                color: var(--v-grey-base);
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){    
    .business-contents{
        max-width: calc(100% - 220px);
    }
    .white-nowrap{
        ::v-deep{
            >br.d-md-block{                
                display: none !important;
            }
        }
    }
}
@media (min-width:1024px){
    .v-list-item:hover{
        .v-list-item__title{
            color: var(--v-primary-base);
            font-weight: 700;
        }
    }
    .business-item:hover{
        --grid-gutter-lg: 8px;
        padding: 0 24px;
        border-color: #fff;
        &::before{
            opacity: 1;
            visibility: visible;
        }
        *:not(.icon-double-quotes){
            color: #fff;
        }
    }
    .white-nowrap{
        ::v-deep{
            >br.d-lg-block{                
                display: none !important;
            }
        }
    }
}
@media (min-width:1200px){
    .white-nowrap{
        ::v-deep{
            >br.d-xl-block{                
                display: none !important;
            }
        }    
    }
}

