
.about-row{
    --line-width: 64px;
    margin: calc(var(--line-width) / -2) 0;
    >[class*="col"]{
        padding: calc(var(--line-width) / 2) 0;
    }
    &__tit{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: var(--line-width);
            height: 4px;
            background-image: url(/images/icon/dot-line.svg);
            background-position: center;
            background-repeat: no-repeat;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(90deg);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .about-row{
        --line-width: 104px;
        margin: 0 calc(var(--line-width) / -2);
        >[class*="col"]{
            padding: 0 calc(var(--line-width) / 2);
        }
        &__tit{
            &::after{
                bottom: unset;
                left: unset;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

