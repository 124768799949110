
.dome{
    --dome-padding: 20px;
    max-width: 628px;
    position: relative;
    padding: var(--dome-padding) var(--dome-padding) 0 0;
    &__bg{
        position: absolute;
        z-index: -1;
        width: calc(100% - var(--dome-padding));
        height: calc(100% - var(--dome-padding));
        background-color: var(--v-primary-lighten2);
        top: 0;
        right: 0;
    }
    &__bg,
    &__img{
        border-radius: 999px 999px 0 0;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .dome{
        --dome-padding: 48px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

