
// Size
.icon--size {
    &-xx-small {
        height: 12px;
    }
    &-x-small {
        height: 14px;
    }
    &-small {
        height: 16px;
    }
    &-default {
        height: 20px;
    }
    &-large {
        height: 22px;
    }
    &-x-large {
        height: 24px;
    }
    &-xx-large {
        height: 26px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon--size {
        &-default {
            height: 24px;
        }
        &-large {
            height: 26px;
        }
        &-x-large {
            height: 28px;
        }
        &-xx-large {
            height: 30px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

