
.hover-line{
    --line-width : 6px;
    &__inner{
        position: relative;
    }
    &__line{
        position: absolute;
        background-color: var(--v-primary-lighten3);
        opacity: 0;
        visibility: hidden;
        transition: .6s ease-out;
        &--1{
            width: 100%;
            height: var(--line-width);
            top: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: left center;
        }
        &--2{
            width: var(--line-width);
            height: 100%;
            top: 0;
            right: 0;
            transform: scaleY(0);
            transform-origin: center top;            
        }
        &--3{
            width: 100%;
            height: var(--line-width);
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: right center;            
        }
        &--4{
            width: var(--line-width);
            height: 100%;
            top: 0;
            left: 0;
            transform: scaleY(0);
            transform-origin: center bottom;            
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .hover-line:hover{
        .hover-line__line{
            opacity: 1;
            visibility: visible;
            &--1{
                transform: scaleX(1);
            }
            &--2{
                transform: scaleY(1);
            }
            &--3{
                transform: scaleX(1);
            }
            &--4{
                transform: scaleY(1);
            }
        }
    }
}
@media (min-width:1200px){
}
